import * as $ from 'jquery';
import 'owl.carousel';



$.fn.extend({
    toggleText: function(stateOne, stateTwo) {
        return this.each(function() {
            stateTwo = stateTwo || '';
            $(this).text() !== stateTwo && stateOne ? $(this).text(stateTwo)
                : $(this).text(stateOne);
        });
    }
});

// cookies
function setCookie(name,value,days) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toGMTString();
    }
    document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function deleteCookie(name) {
    setCookie(name,"",-1);
}

function hasVoted(dataId){
    var userVoted = readCookie('user_voted_'+ dataId);
    if( userVoted ){
        return true;
    }
    return false;
}

function hasClicked(data){
    var userClicked = readCookie('user_clicked_'+ data);
    if( userClicked ){
        return true;
    }
    return false;
}



var userip = readCookie("userip");
var adultUserip = readCookie("adult_userip");

if (!userip){
    $('#warning_popup').fadeIn(200).fadeTo(400, 1);
}
else{
    $('#warning_popup').hide();
}
if (!adultUserip){
    setTimeout(function () {
        $('#adult_warning_popup').fadeIn(200).fadeTo(400, 1);
    }, 1000);

} else{
    $('#adult_warning_popup').hide();
}
$('#warning_popup_close').on('click', function(){
    var now = new Date();
    now.setTime(now.getTime() + 24 * 3600 * 1000);
    var ip = $("input[name='ip']").val();
    document.cookie = "userip=" + ip +";path=/;expires="+ now.toUTCString();
    $('#warning_popup').fadeOut(200).fadeTo(400, 1);
});

$('#adult_warning_popup_close').on('click', function(){
    var now = new Date();
    now.setTime(now.getTime() + 24 * 3600 * 1000);
    var ip = $("input[name='adult_ip']").val();
    document.cookie = "adult_userip=" + ip +";path=/;expires="+ now.toUTCString();
    $('#adult_warning_popup').fadeOut(200).fadeTo(400, 1);
});

/*Scroll top*/
$(window).scroll(function(){
    if ($(this).scrollTop() > 100) {
        $(".to-top").addClass('active');
    }
    else {
        $(".to-top").removeClass('active');
    }
});

/*Scroll top Click*/
$(".to-top").on("click", function () {
    $('html, body').animate({scrollTop: 0}, 1000);
});


/*
* Stylisation for menu elements
 */
function elDelimiter(){
    $('.left-block  > nav > ul > li > .empty-link').each(function(){
        var li = $(this).parent();
        var span = $(li).find(".dotted-line");
        var width = li.width() - $(this).width() - 60;
        $(span).css({'width': width + "px"});
    });
    $("#menu-main-menu li a").each(function (){
        var li = $(this).parent();
        var span = $(li).find(".dotted-line");
        var width = li.width() - $(this).width() - 60;
        $(span).css({'width': width + "px"});
    });
}

function isScrolledIntoView(elem){
    if(elem.length !== 0){
        var $elem = $(elem);
        var $window = $(window);
        var docViewTop = $window.scrollTop();
        var docViewBottom = docViewTop + $window.height();
        var elemTop = $elem.offset().top;
        var elemBottom = elemTop + $elem.height();
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
    else{
        return false;
    }
}

$(document).ready(function(){
    "use strict";

    elDelimiter();

    // Rating
    var $jsRating = $(".js-rating");

    $jsRating.hover(
        function(e) {
        },function(e){
            var dataId = $(this).data('id');
            if( !hasVoted(dataId) ) {
                var innerDefaulDatatWidth = $(this).data('default-value');
                var defaultWidth = parseInt(innerDefaulDatatWidth) * 20;
                $(this).find('.js-rating_inner').css("width", defaultWidth + "%");
            }
        }
    );
    $jsRating.mousemove(function(e){
        var dataId = $(this).data('id');
        if( !hasVoted(dataId) ){
            var actionWidth = e.offsetX;
            if(actionWidth > 100){
                actionWidth = 100;
            }
            $(this).find('.js-rating_inner').css("width", actionWidth + "%");
        }
    });
    $jsRating.on('click',function(e) {
        var dataId = $(this).data('id');
        if( !hasVoted(dataId) ){
            var $that = $(this);
            var dataVotes = $that.data('votes');
            var updatedVotes = dataVotes+1;
            var innerWidth = $that.find('.js-rating_inner').width();
            var perc = e.offsetX;
            $that.find('.js-rating_inner').css("width", perc + "%");
            $.ajax({
                type: 'POST',
                url: votesScript.url,
                data:{
                    action: votesScript.action,
                    nonce: votesScript._wp_nonce,
                    data_id: dataId,
                    rating: innerWidth,
                    votes: updatedVotes
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    if( res.status === 'success' ){
                        $('.js-votes').text(updatedVotes);
                        $jsRating.data('votes',updatedVotes);
                        $jsRating.data('default-value',innerWidth);
                        setCookie('user_voted_'+ dataId,'voted',5);
                    }else{
                        console.log(res.status);
                    }
                }
            });
        }
    });

    // Likes clicks
    $('.js-like').on('click',function(){
        var currentItem = $('.gallery-item-js.active');
        var currentPostId = parseInt(currentItem.data('post-id'));
        var activeSlide = parseInt(currentItem.data('order'));
        var srcUrl = currentItem.data('src-url');
        var currentLikes = parseInt(currentItem.data('likes'));
        if( !hasClicked(srcUrl) ){
            $.ajax({
                type: 'POST',
                url: likesScript.url,
                data:{
                    action: likesScript.action,
                    nonce: likesScript._wp_nonce,
                    post_id: currentPostId,
                    active_slide: activeSlide,
                    current_likes: currentLikes
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    if( res.status === 'success' ){
                        $('.total-virtual-likes').text(currentLikes+1);
                        currentItem.find('.likes-total').text(currentLikes+1);
                        setCookie('user_clicked_'+ srcUrl,'clicked',5);
                    }else{
                        console.log(res.status);
                    }
                }
            });
        }else{
            console.log('You have already clicked this photo');
        }

    });







    var siteBody = $("body");
    var siteHtml = $("html");
    /**
     * ********************************************************
     * ---------------------Scroll Effects---------------------
     * ========================================================
     * */


    var wwaItem = document.getElementsByClassName('wwa-item');
    function wwaEffect(k){
        var z = wwaItem[k];
        setTimeout(function() {$(z)}, 1 + k * 500);
    }
    var wwdItem = document.getElementsByClassName('wwd-item');
    function wwdEffect(l){
        var w = wwdItem[l];
        setTimeout(function() {$(w).css({'opacity': 1})}, 1 + l * 500);
    }
    var gallItem = document.getElementsByClassName('gallery-item');
    function gallEffect(m){
        var g = gallItem[m];
        setTimeout(function() {$(g).css({'opacity': 1})}, 1 + m * 500);
    }
    var ratingItem = document.getElementsByClassName('rating-item');
    function ratingEffect(m){
        var r = ratingItem[m];
        setTimeout(function() {$(r).css({'opacity': 1})}, 1 + m * 500);
    }
    var prlItem = document.getElementsByClassName('price-list-item');
    function prlEffect(m){
        var p = prlItem[m];
        setTimeout(function() {$(p).css({'opacity': 1})}, 1 + m * 500);
    }
    //c-team-item
    var ctItem = document.getElementsByClassName('c-team-item');
    function ctEffect(m){
        var g = ctItem[m];
        setTimeout(function() {$(g).css({'opacity': 1})}, 1 + m * 500);
    }


    (function($) {
        var clientHeight = $(window).height();
        var items = [];
        $(document).scroll(function() {
            if(isScrolledIntoView($('.wwa-item'))){
                for(var k = 0; k < wwaItem.length; k++){
                    wwaEffect(k);
                }
            }
            if(isScrolledIntoView($('.wwd-item'))){
                for(var l = 0; l < wwdItem.length; l++){
                    wwdEffect(l);
                }
            }
            if(isScrolledIntoView($('.gallery-item'))){
                for(var m = 0; m < gallItem.length; m++){
                    gallEffect(m);
                }
            }
            if(isScrolledIntoView($('.rating-item'))){
                for(var m = 0; m < ratingItem.length; m++){
                    ratingEffect(m);
                }
            }
            if(isScrolledIntoView($('.price-list-item'))){
                for(var n = 0; n < prlItem.length; n++){
                    prlEffect(n);
                }
            }
            if(isScrolledIntoView($('.c-team-item'))){
                for(var o = 0; o < ctItem.length; o++){
                    ctEffect(o);
                }
            }


            for(var i = 0; i < items.length; i++) {
                if(items[i].ele.offset().top - $(document).scrollTop() - clientHeight < 350) {
                    if(typeof items[i].arg === 'string') {
                        items[i].ele.addClass(items[i].arg);
                    } else if(items[i].arg instanceof Function) {
                        items[i].arg.call();
                    }
                }
            }
        });
        $.fn.extend({
            listenedByScroll: function(arg) {
                $(document).scroll();
                if(!arg) return this;
                items.push({ele: $(this), arg: arg});
                return this;
            }
        });
    })($);

    /*Menu*/

    $(".trigger").on('click',function () {
        $('.left-block').slideToggle();
        $(this).toggleClass("opened");
        elDelimiter();
    });

    // Testimonials custom slider
    var sw = $(".viewport").width();
    var sliderTimer;
    var $slideWrapper = $('.slidewrapper');

    $(function () {
        $slideWrapper.width($slideWrapper.children().length * sw);
        sliderTimer = setInterval(nextSlide, 3000);
        $('.viewport,.dot').hover(function () {
            clearInterval(sliderTimer);
        }, function () {
            sliderTimer = setInterval(nextSlide, 3000);
        });
        $('#next_slide').click(function () {
            clearInterval(sliderTimer);
            nextSlide();
            sliderTimer = setInterval(nextSlide, 3000);
        });
        $('#prev_slide').click(function () {
            clearInterval(sliderTimer);
            prevSlide();
            sliderTimer = setInterval(nextSlide, 3000);
        });
        $('.dot').click(function () {
            $('.dot.active').removeClass('active');
            $(this).addClass('active');
            var n = $('.dot').index(this);
            certainSlide(n);
        });
    });

    function nextSlide() {
        var currentSlide = parseInt($slideWrapper.data('current'));
        currentSlide++;
        if (currentSlide >= $slideWrapper.children().length) {
            currentSlide = 0;
        }
        $('.dot.active').removeClass('active');
        $('.dot').eq(currentSlide).addClass('active');
        $slideWrapper.animate({left: -currentSlide * sw}, sw).data('current', currentSlide);
    }

    function prevSlide() {
        var currentSlide = parseInt($slideWrapper.data('current'));
        currentSlide--;
        if (currentSlide < 0) {
            currentSlide = $slideWrapper.children().length - 1;
        }
        $('.dot.active').removeClass('active');
        $('.dot').eq(currentSlide).addClass('active');
        $slideWrapper.animate({left: -currentSlide * sw}, sw).data('current', currentSlide);
    }

    function certainSlide(n) {
        var currentSlide = n;
        $slideWrapper.animate({left: -currentSlide * sw}, sw).data('current', currentSlide);
    }

    /**
     * *************************************************
     * ============== Owl Carousel sliders =============
     * *************************************************
     */
    // Top slider
    if ( $('.home').length !== 0 ){
        $('#top_slider').owlCarousel({
            loop:true,
            lazyLoad: true,
            animateOut: 'fadeOut',
            smartSpeed:1000,
            autoplay:true,
            autoplayTimeout:5000,
            autoplayHoverPause:true,
            items:1
        });
        $("#owl_prices").owlCarousel({
            items : 3,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:1
                },
                769:{
                    items:1
                },
                1200:{
                    items:3
                }
            },
            loop: true,
            lazyLoad: true,
            nav: true,
            navText: ["<i class='fa fa-angle-double-left'></i>","<i class='fa fa-angle-double-right'></i>"],
            animateOut:'fadeOut',
            autoplay:true,
            autoplayTimeout:5000,
            autoplayHoverPause:true
        });

        $("#owl-team").owlCarousel({
            items : 3,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:3
                },
                1000:{
                    items:3
                }
            },
            loop: true,
            lazyLoad: true,
            nav: true,
            navText: ["<i class='fa fa-angle-double-left'></i>","<i class='fa fa-angle-double-right'></i>"],
            animateOut:'fadeOut',
            autoplay:true,
            autoplayTimeout:5000,
            autoplayHoverPause:true
        });

    }

    /*$('.main-menu li.menu-item-has-children').append('<span class="subMenuButton fa fa-angle-down"></span>');
    $('.subMenuButton').on('click',function () {
        var t = $(this);
        if(t.hasClass('open')) {
            t.removeClass('open').prev().slideUp(300);
        } else {
            t.removeClass('open').prev().slideDown(300);
            t.addClass('open');
        }
    });*/
    //Menu Close
    $(document).mouseup(function (e){
        var div = $(".main-menu,#menuOpen");
        if (!div.is(e.target)
            && div.has(e.target).length === 0) {
            div.removeClass('opened');
            $('.hamburger').removeClass('is-active');
            //siteBody.removeClass('shadow');
            //siteHtml.removeClass('shadow');
        }
    });

    /*Scroll top*/
    $(window).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $(".to-top-btn").addClass('active');
        }
        else {
            $(".to-top-btn").removeClass('active');
        }
    });

    /*Scroll top Click*/
    $(".to-top-btn").on("click", function () {
        $('html, body').animate({scrollTop: 0}, 1000);
    });

    $('.js-faq-question').on('click',function(){
        const $faqItem = $('.js-faq-block');
        const $answer = $('.js-faq-answer');
        if( $(this).parent().hasClass('active') ){
            $(this).parent().removeClass('active');
            $(this).parent().find('.js-faq-answer').slideUp(300);
        }else{
            //$faqItem.removeClass('active');
            $(this).parent().addClass('active');
            // $answer.slideUp(300);
            $(this).parent().find('.js-faq-answer').slideDown(300);
        }


        //$(this).hasClass("opened") ? $(this).next().slideToggle(300);
        //$(this).removeClass("opened");
        //$(this).next().slideToggle(300);
        //$(this).addClass("opened");
        //$(this).parent().addClass('active');
        //$(this).closest('js-faq-answer').addClass('active');
    });



    // 404 go back
    $('.back').on('click', function(){
        setTimeout('history.back()', 1000);
    });

    /**
     * Displaying comment by click
     */
    var $body = $('body');

    $body.on('click','.show-comments-js',function(){
        $('#comments').slideToggle();
        $(this).find('i').toggleClass("fa-caret-up fa-caret-down");
        $(this).find('.show-comments-text-js').toggleText('Hide Comments','Show Comments');
    });

    /**
     * Comments rating
     */
    $body.on('change',"input[name='rating']",function(){
        var rating = parseInt($(this).val());
        $('.heart-keeper').each(function(i){
            if( i+1 <= rating ){
                $(this).find('i').removeClass('fa-heart-o').addClass('fa-heart');
            }else{
                $(this).find('i').removeClass('fa-heart').addClass('fa-heart-o');
            }
        })
    });


    /**
     *  ============= Gallery Slider ==================
     * ************************************************
     */
    var lastX,
        run,
        allItems = $('.g-gallery-item').length - 1;
    var gallerySlide = $('#gallery_slide');
    // Exit from gallery
    $('.g-exit-js').on('click',function(){
        $('#g_play').removeClass('disabled active').addClass('enabled');
        $('#g_stop').removeClass('disabled active');
        clearInterval(run);
        $('#gallery_popup_slider').fadeOut();
    });

    // Exit from gallery by click outside
    $('#gallery_popup_slider').on('click',function(event){
        if (!$(event.target).closest('.gallery-navigation').length && !$(event.target).closest("#gallery_slide img").length) {
            $('#g_play').removeClass('disabled active').addClass('enabled');
            $('#g_stop').removeClass('disabled active');
            clearInterval(run);
            $("#gallery_popup_slider").fadeOut();
        }
    });

    // Prev and Next slide by touch move in mobiles
    gallerySlide.bind('touchstart','img', function(e) {
        lastX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
    });
    gallerySlide.bind('touchmove','img', function(e) {
        var currentX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
        if (Math.abs(currentX-lastX) < 30) { return; }
        if (currentX > lastX) {
            prevSlideAction();
        } else {
            nextSlideAction();
        }
    });


    // Previous slide event handler
    $('#g_prev').on('click',prevSlideAction);

    function prevSlideAction(){
        var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
        var prevSlide = getPrevSlide(activeSlide);
        $('.gallery-item-js').removeClass('active');
        var prevObj =  $('.g-gallery-item').eq(prevSlide);
        $(prevObj).addClass('active');
        generateSlideMarkup(prevObj);
    }

    function getPrevSlide(activeSlide){
        if(activeSlide > 0){
            return activeSlide-1;
        }
        if(activeSlide === 0){
            return allItems;
        }
    }

    // Next slide
    $('#g_next').on('click',nextSlideAction);

    function nextSlideAction(){
        var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
        var nextSlide = getNextSlide(activeSlide);
        $('.gallery-item-js').removeClass('active');
        var nextObj =  $('.g-gallery-item').eq(nextSlide);
        $(nextObj).addClass('active');
        generateSlideMarkup(nextObj);
    }

    function getNextSlide(activeSlide){
        if(activeSlide < allItems){
            return activeSlide+1;
        }
        if(activeSlide === allItems){
            return 0;
        }
    }

    $('.g-control').on('click',function(){
        $('.g-control').removeClass('active');
        $(this).addClass('active');
    });

    $('#g_play').on('click',function(){
        if( $(this).hasClass('enabled') ){
            run = setInterval(function(){ gallerySliderRun()},3000);
            $(this).removeClass('enabled').addClass('disabled');
        }
    });

    $('#g_stop').on('click',function(){
        $('#g_play').removeClass('disabled').addClass('enabled');
        clearInterval(run);
    });

    function gallerySliderRun(){
        if( !$('.gallery-items').find('#gallery-navigation').hasClass('stop') ){
            var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
            var nextSlide = getNextSlide(activeSlide);
            $('.gallery-item-js').removeClass('active');
            var nextObj =  $('.g-gallery-item').eq(nextSlide);
            $(nextObj).addClass('active');
            generateSlideMarkup(nextObj);
        }
    }
    $('#g_info').on('click',function(){
        $(this).toggleClass('active');
        $('#gallery_slide_info').toggle();
    });
    $('.gallery-item-js').on('click',function(){
        $('.gallery-item-js').removeClass('active');
        $(this).addClass('active');
        generateSlideMarkup(this);
    });

    function generateSlideMarkup(obj){
        var dataSrc = $(obj).data('src-url');
        var dataPhAuthor = $(obj).data('ph-author') ? '<h4>' + $(obj).data('ph-author') + '</h4>' : '' ;
        var dataPhTitle = $(obj).data('ph-title') ? '<p>' + $(obj).data('ph-title') + '</p>' : '';
        var dataPhDescription = $(obj).data('ph-description') ? '<p>' + $(obj).data('ph-description') + '</p>' : '';
        var dataLikes = $(obj).data('likes');
        $('#gallery_popup_slider').fadeIn();
        $('#gallery_slide').html("<img src='"+dataSrc+"'" + "alt='slide' >");
        $('#gallery_slide_info').html(dataPhAuthor+dataPhTitle+'<p><i class="fa fa-heart" aria-hidden="true"></i><span class="total-virtual-likes">'+dataLikes+'</span></p>'+dataPhDescription);

    }

    /**
     * **************************************************************
     */

    /**
     * ----------- Services page Tabs ----------
     */
    if( $('.skills-widget-wrapper').length ) {
        $('.skill-item-js').on('click',function(){
            var dataNav = $(this).data('nav');
            $('.skill-item-js').removeClass('active');
            $(this).addClass('active');
            $('.tab-content-item-js').removeClass('active');
            $('[data-tab-content='+dataNav+']').addClass('active');
        });
    }

    /**
     * ----------- Scroll down to services list ------------------
     */
    $(".btn-scroll-down-js").on('click',function(e) {
        var t = $(".services-list h2").offset();
        $("html, body").animate({
            scrollTop: t.top
        }, 500)
    });

    /**
     * ----------- Scroll down to Who we are section ----------------
     */
    $(".hero-btn-scroll-down-js").on('click',function(e) {
        var t = $(".who-we-are h2").offset();
        $("html, body").animate({
            scrollTop: t.top
        }, 500)
    });



    /**
     * +++++++++++++++++++++++++++++++++++++++++++++++++
     * ===================== Ajax solutions ============
     * +++++++++++++++++++++++++++++++++++++++++++++++++
     */

    // Loads comments by Ajax

    $('.load-comments-js').on('click',function(e){
        e.preventDefault();
        var currentId = $(this).data('current-id');
        // var dataModeration = $(this).data('moderation');
        $.ajax({
            method: 'POST',
            url: localizedScript.url,
            data: {
                action: 'rw_load_comments',
                postID: currentId,
                //dataModeration: dataModeration
            },

            success: function( data ) {
                $( '#comments_wrapper' ).html( data );
                $('#load_comments').hide();
            }
        });
    });



    // Load More
    $('#rw_show_more').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var showMore = $('#rw_show_more');
        var loader = $('#loader');
        var showMoreText = showMore.text();
        var maxNumPages = $(this).data('max-pages');
        var queryVars = $(this).data('query-vars');
        var currentPage = $(this).data('current-page');
        var data = {
            'action': 'loadmore',
            'query_vars': queryVars,
            'page' : currentPage
        };
        showMore.hide();
        loader.show();
        $.ajax({
            url:localizedScript.url,
            data:data,
            type:'POST',
            success:function(data){
                if( data ) {
                    $('.load-more-wrapper').before(data);
                    showMore.data('current-page',currentPage + 1);
                    showMore.show();
                    loader.hide();
                    if (currentPage === maxNumPages){
                        showMore.parent().hide();
                        showMore.remove();
                        loader.hide();
                    }
                } else {
                    showMore.parent().hide();
                    showMore.remove();
                    loader.hide();
                }
            }
        });
    });

    // Ropebook show more

    $('#rb_show_more').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var showMore = $('#rb_show_more');
        var loader = $('#rb_loader');
        var maxNumPages = $(this).data('max-pages');
        var queryVars = $(this).data('query-vars');
        var currentPage = $(this).data('current-page');
        var data = {
            'action': 'rbloadmore',
            'query_vars': queryVars,
            'page' : currentPage
        };
        showMore.hide();
        loader.show();
        $.ajax({
            url:localizedScript.url,
            data:data,
            type:'POST',
            success:function(data){
                if( data ) {
                    $('.load-more-wrapper').before(data);
                    showMore.data('current-page',currentPage + 1);
                    showMore.show();
                    loader.hide();
                    if (currentPage === maxNumPages){
                        showMore.parent().hide();
                        showMore.remove();
                        loader.hide();
                    }
                } else {
                    showMore.parent().hide();
                    showMore.remove();
                    loader.hide();
                }
            }
        });
    });

    /**
     * *************************************************
     * ================ FORMS ==========================
     * *************************************************
     */
    // hero form
    $('#hero_cta_form').on('submit',function(e){
        e.preventDefault();
        var hcNonce = $(this).find("input[name='hero_nonce']").val();
        var hcName = $('#hero_cta_name').val();
        var hcEmail = $('#hero_cta_email').val();
        var hcService = $('#hero_cta_service').val();
        var hcMsg = $('#hero_cta_msg').val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(hcEmail);
        if(hcName === ''){
            $("#hero_cta_name").css({'border':'1px solid red'});
            $("#hero_cta_name_error").text('Name is required');
        }else{
            $("#hero_cta_name").css({'border':'1px solid #80060a'});
            $("#hero_cta_name_error").text('');
        }
        if( hcEmail === '' ){
            $("#hero_cta_email").css({'border':'1px solid red'});
            $("#hero_cta_email_error").text('Email is required');
        }else if( check_mail !== true ){
            $("#hero_cta_email").css({'border':'1px solid red'});
            $("#hero_cta_email_error").text('Not valid email');

        }else{
            $("#hero_cta_email").css({'border':'1px solid #80060a'});
            $("#hero_cta_email_error").text('');
        }
        if( hcName !== '' && check_mail === true ){
            $("#hero_cta_email").css({'border':'1px solid #80060a'});
            $("#hero_cta_email_error").text('');
            $("#hero_cta_name").css({'border':'1px solid #80060a'});
            $("#hero_cta_name_error").text('');
            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'hero_cta_form',
                    nonce: hcNonce,
                    user_name: hcName,
                    user_email: hcEmail,
                    user_service: hcService,
                    user_message: hcMsg,
                    //captcha: grecaptcha.getResponse()
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    console.log(result);
                    if(res.status === 'success'){
                        $('#hero_cta_form')[0].reset();
                        //grecaptcha.reset();
                        $('.hero-thanks-popup').css({'display':'flex'});
                        setTimeout(function(){
                            $('.hero-thanks-popup').fadeOut();
                            $('.hero-cta-form-wrapper').fadeOut();
                            siteBody.removeClass('shadow');
                            siteHtml.removeClass('shadow');
                        }, 3000);
                    }
                    else if(res.status === 'failed'){
                        /*$('.recaptcha-error-msg').html('<p>Oops... Validation is not passed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);*/
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }
            });
        }
    });
    $('.hero-cta-js').on('click',function(){
        $('.hero-cta-form-wrapper').fadeIn();
        siteBody.addClass('shadow');
        siteHtml.addClass('shadow');

    });
    $('.hero-form-close-js').on('click',function(){
        $('.hero-cta-form-wrapper').fadeOut();
        siteBody.removeClass('shadow');
        siteHtml.removeClass('shadow');

    });
    $('.hero-cta-form-wrapper').on('click',function(event){
        if (!$(event.target).closest("form").length) {
            $(".hero-cta-form-wrapper").fadeOut();
            siteBody.removeClass('shadow');
            siteHtml.removeClass('shadow');
        }
    });

    // Home contact form
    $('#hc_form').on('submit',function(e){
        e.preventDefault();
        var $that = $(this);
        var nonce = $that.find("input[name='hc_nonce']").val();
        var customerEmail = $that.find("input[name='hc_email']").val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(customerEmail);
        var userName = $that.find("input[name='hc_name']").val();
        if( customerEmail === '' ){
            $that.find("input[name='hc_email']").css({'border':'1px solid red'});
            $("#hc_email_error").text('Email is required');
        }
        else if( check_mail !== true ){
            $that.find("input[name='hc_email']").css({'border':'1px solid red'});
            $("#hc_email_error").text('Not valid email');

        }else{
            $that.find("input[name='hc_email']").css({'border':'1px solid white'});
            $("#hc_email_error").text('');
        }
        if( userName === '' ){
            $that.find("input[name='hc_name']").css({'border':'1px solid red'});
            $("#hc_name_error").text('Name is required');
        }else{
            $that.find("input[name='hc_name']").css({'border':'1px solid white'});
            $("#hc_name_error").text('');
        }
        if( check_mail === true && userName !== '' ){
            $that.find("input[name='hc_email']").css({'border':'1px solid white'});
            $that.find("input[name='hc_name']").css({'border':'1px solid white'});
            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'home_contact_form',
                    nonce: nonce,
                    user_name: userName,
                    user_email: customerEmail,
                    //captcha: grecaptcha.getResponse()
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    console.log(result);
                    if(res.status === 'success'){
                        $that[0].reset();
                        // grecaptcha.reset();
                        $('.thank-popup-wrapper').fadeIn();
                        setTimeout(function(){$('.thank-popup-wrapper').fadeOut()}, 3000);
                    }
                    else if(res.status === 'user_already_exists'){
                        $("input[name='hc_email']").css({'border':'1px solid red'});
                        $("#hc_email_error").text('Email: '+customerEmail+' is already registered');
                        //grecaptcha.reset();
                    }
                    else if(res.status === 'post_not_set'){
                        alert('Oops... Validation is not passed!');
                        /*$('.recaptcha-error-msg').html('<p>Oops... Validation is not passed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);*/
                    }
                    else if(res.status === 'failed'){
                        /* $('.recaptcha-error-msg').html('<p>Oops... Recaptcha Validation failed!</p>');
                         setTimeout(function(){
                             $('.recaptcha-error-msg').html('');
                         }, 3000);*/
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }
            });
        }
    });

    // Contact us contact form + mask
    if( document.getElementById('cu_phone') ){
        document.getElementById('cu_phone').addEventListener('input', function (e) {
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,6})/);
            e.target.value = !x[2] ? x[1] : '+' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
        });
    }

    $('#cu_form').on('submit',function(e){
        e.preventDefault();
        var $that = $(this);
        var nonce = $that.find("input[name='cu_nonce']").val();
        var customerEmail = $that.find("input[name='cu_email']").val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(customerEmail);
        var userName = $that.find("input[name='cu_name']").val();
        var userCompany = $that.find("input[name='cu_company']").val();
        var userSubject = $that.find("input[name='cu_subject']").val();
        var userMessage = $that.find("textarea[name='cu_message']").val();
        var userPhone = $that.find("input[name='cu_phone']").val();
        var phone_pattern = /^\+?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{0,6})$/;
        var check_phone = phone_pattern.test(userPhone);
        if( userPhone !== '' && check_phone !== true ){
            $that.find("input[name='cu_phone']").css({'border':'1px solid red'});
            $("#cu_phone_error").text('Not valid phone');
            setTimeout(function(){$that.find("input[name='cu_phone']")}, 3000);
        }
        else if (userPhone === ''){
            $that.find("input[name='cu_phone']").css({'border':'1px solid red'});
        }
        else{
            $that.find("input[name='cu_phone']").css({'border':'1px solid #F4F4F4'});
            $("#cu_phone_error").text('');
        }
        if( customerEmail === '' ){
            $that.find("input[name='cu_email']").css({'border':'1px solid red'});
            setTimeout(function(){$that.find("input[name='cu_email']")}, 3000);
            $('#cu_email_error').text('');
        }
        else if( check_mail !== true ){
            $that.find("input[name='cu_email']").css({'border':'1px solid red'});
            setTimeout(function(){$that.find("input[name='cu_email']")}, 3000);
            $('#cu_email_error').text('Not valid email');
        }else{
            $that.find("input[name='cu_email']").css({'border':'1px solid #F4F4F4'});
            $('#cu_email_error').text('');
        }
        if( userName === '' ){
            $that.find("input[name='cu_name']").css({'border':'1px solid red'});
            setTimeout(function(){$that.find("input[name='cu_name']")}, 3000);
        }else{
            $that.find("input[name='cu_name']").css({'border':'1px solid #F4F4F4'});
        }
        if( check_phone === true && check_mail === true && userName !== '' ){
            $that.find("input[name='cu_email']").css({'border':'1px solid #F4F4F4'});
            $that.find("input[name='cu_name']").css({'border':'1px solid #F4F4F4'});
            $that.find("input[name='cu_phone']").css({'border':'1px solid #F4F4F4'});
            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'contact_us_form',
                    nonce: nonce,
                    user_name: userName,
                    user_email: customerEmail,
                    user_phone: userPhone,
                    user_company: userCompany,
                    user_subject: userSubject,
                    user_message: userMessage,
                    captcha: grecaptcha.getResponse()
                },
                success: function (result) {
                    console.log(result);
                    var res = JSON.parse(result);
                    if(res.status === 'success'){
                        $that[0].reset();
                        grecaptcha.reset();
                        $('.thank-popup-wrapper').fadeIn();
                        setTimeout(function(){$('.thank-popup-wrapper').fadeOut()}, 3000);
                    }
                    else if(res.status === 'failed'){
                        grecaptcha.reset();
                        $('.recaptcha-error-msg').html('<p style="color:red;">Oops... Recaptcha is not passed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                    else if(res.status === 'post_or_nonce_not_set'){
                        grecaptcha.reset();
                        $('.recaptcha-error-msg').html('<p style="color:red;">Oops... Validation is not passed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }
            });
        }
    });

    $('.order-btn-js').on('click',function(){
        var propositionId = $(this).data('order');
        siteBody.addClass('shadow');
        siteHtml.addClass('shadow');
        $('.footer-order-popup').css({'display':'flex'});
        $('#ff_order').val(propositionId);
    });
    $('.footer-order-form-close-js').on('click',function(){
        $('.footer-order-popup').fadeOut();
        siteBody.removeClass('shadow');
        siteHtml.removeClass('shadow');
    });
    $('.footer-order-popup').on('click',function(event){
        if (!$(event.target).closest("form").length) {
            $(".footer-order-popup").fadeOut();
            siteBody.removeClass('shadow');
            siteHtml.removeClass('shadow');
        }
    });
    $('#order_form').on('submit',function(e){
        e.preventDefault();
        var $that = $(this);
        var nonce = $that.find("input[name='order_nonce']").val();
        var orderId = $that.find("input[name='ff_order']").val();
        var orderClientEmail = $that.find("input[name='ff_email']").val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(orderClientEmail);
        if( orderClientEmail === '' ){
            $that.find("input[name='ff_email']").css({'border':'1px solid red'});
            $("#ff_email_error").text('Email is required');
        }
        else if( check_mail !== true ){
            $that.find("input[name='ff_email']").css({'border':'1px solid red'});
            $("#ff_email_error").text('Not valid email');
        }else{
            $that.find("input[name='ff_email']").css({'border':'1px solid #ccc'});
            $("#ff_email_error").text('');
        }
        if( check_mail === true && orderId !== '' ){
            $that.find("input[name='ff_email']").css({'border':'1px solid #ccc'});
            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'footer_order_form',
                    nonce: nonce,
                    order_id: orderId,
                    user_email: orderClientEmail
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    console.log(result);
                    if(res.status === 'success'){
                        $that[0].reset();
                        $('.thank-popup-wrapper').fadeIn();
                        setTimeout(function(){
                            $('.footer-order-popup').fadeOut();
                            $('.thank-popup-wrapper').fadeOut();
                            siteBody.removeClass('shadow');
                            siteHtml.removeClass('shadow');
                        }, 3000);
                    }
                    else if(res.status === 'failed'){
                        $('#ff_email_error').text('Oops... Something went wrong!');
                        setTimeout(function(){
                            $('#ff_email_error').text('');
                        }, 3000);
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }
            });
        }
    });
});

